




























































































import { Component, Vue,Watch } from "vue-property-decorator";
import MyForm from "@/components/MyForm.vue";
import Mixin from "@/js/mixins";

@Component({ components: { MyForm }, mixins: [Mixin] })
export default class UserInfo extends Vue {
  public member = {};
  public showSuccess = false;
  public user_id = '';
  public balance_id = '';
  public info:any = {};
  public number:any = null;
  public canSub:boolean = false;
  public showKeyboard = false;
  public password = "";
  public checked:boolean = false;
  // public weihao = ''
  // 获取用户信息
//   getMember() {
//     let _this = this;
//     this.$api.request({
//       url: "user/member/info",
//       success(res) {
//         _this.member = res.data;
//       }
//     });
//   }
sub(){
  if(this.canSub){
    if(this.checked){
      this.showKeyboard = true;
      this.password = "";
    }else{
      this.$toast('请勾选相关协议')
    }
    
  }
}

@Watch("password")
  getpassword(to, from) {
    if (to.length == 6) {
      console.log("进行验证");
      let _this = this
      this.$api.request({
        url: "balance/user/add-user-withdrawal",
        data: {
          password:this.password,
          user_id:this.user_id,
          balance_id:this.balance_id,
          balance:this.number
        },
        success(res) {
            _this.showSuccess = true;
        },
        fail(err){
			 _this.$toast(err)
             
        }
      });
    }
  }
  onDelete() {
    this.password = this.password.slice(0, this.password.length - 1);
  }
  onInput(key) {
    this.password = (this.password + key).slice(0, 6);
  }

@Watch('number')
getNumber(e){
    if(e>0&&Number(e)<this.info.user_balance){//能提交
        this.canSub = true
        this.$toast.clear()
    }else if(e==0){
        this.canSub = false
    }else if(Number(e)>this.info.user_balance){
        this.$toast('已超出当前账户余额！')
        this.canSub = false
    }
}
    get shouxu(){
        return (this.number*this.info.balance_info.withdraw_fee).toFixed(2)
    }
    getInfo() {
    let _this = this;
    this.$api.request({
      url: "balance/user/user-money",
      data:{user_id:this.user_id,balance_id:this.balance_id},
      success(res) {
          _this.info = res.data
          if(_this.info.bank){
            _this.info.bank.bank_account = _this.info.bank.bank_account.slice(15,20)
          }else{
            _this.$toast('请先绑定银行卡')
          }
          
      }
    });
  }
  init() {
    this.user_id = String(this.$route.query.user_id || "");
	this.balance_id = String(this.$route.query.balance_id || "");
	
    this.getInfo()
    this.showSuccess = false
    this.number = ''
  }
}
